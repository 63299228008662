

h1, h2, h3 {
    color: #2c3e50;
}

h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
}

h2 {
    font-size: 1.5em;
    margin-top: 1em !important;
    margin-bottom: 0.5em !important;
}

h3 {
    font-size: 1.2em;
    margin-top: 1em !important;
    margin-bottom: 0.5em !important;
}

p {
    margin: 1em 0;
}

ul {
    list-style-type: disc;
    margin: 1em 0;
    padding-left: 40px;
}

li {
    margin: 0.5em 0;
}

a {
    color: #2980b9;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}


